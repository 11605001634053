import logo from './logo.svg';
import './App.css';
import Main from './Main/Main';


function App() {
  return (
    <>
    
    <Main></Main>

    </>

  );
}

export default App;
