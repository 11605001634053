import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

const GLBModel = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        // יצירת סצנה
        const scene = new THREE.Scene();

        // הגדרת גודל הקנבס
        const sizes = {
            width: 250,
            height: 250,
        };

        // יצירת Renderer
        const renderer = new THREE.WebGLRenderer({
            canvas: mountRef.current,
            antialias: true,
        });
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.setClearColor(0x076c83);

        // יצירת מצלמה
        const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
        camera.position.set(1, 1, 10);
        scene.add(camera);

        // הוספת שליטה על המצלמה עם ריכוך
        const controls = new OrbitControls(camera, renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.1;

        // הוספת תאורה לסצנה
        const ambientLight = new THREE.AmbientLight(0x404040, 8); // תאורה כללית רכה
        scene.add(ambientLight);

        const pointLight = new THREE.PointLight(0xff0000, 8, 50); // תאורה אדומה, עוצמה 4, טווח 50
        pointLight.position.set(0, 0, 0); // מיקום התאורה
        scene.add(pointLight);

        const directionalLight = new THREE.DirectionalLight(0xffffff, 3); // תאורה מכוונת
        directionalLight.position.set(5, 5, 5); // מיקום התאורה
        scene.add(directionalLight);

        // הוספת תאורה מהשמיים עם HemisphereLight
        const hemisphereLight = new THREE.HemisphereLight(0xaaaaaa, 0x000000, 20); // תאורת שמיים צבע אפור בהיר
        hemisphereLight.position.set(0, 1, 0); // מיקום התאורה
        scene.add(hemisphereLight);

        // טעינת המודל GLB
        const gltfLoader = new GLTFLoader();
        let model; // משתנה לשמירת המודל
        gltfLoader.load(
            '/pp.glb',  // הנתיב מתוך תיקיית public
            (gltf) => {
                model = gltf.scene;
                model.scale.set(1, 1, 1);  // הגדרת הגודל של המודל
                model.position.set(0, 0, 0);  // מיקום המודל בסצנה
                scene.add(model);
                console.log('Model Loaded Successfully');
            },
            (progress) => {
                console.log(`Loading... ${((progress.loaded / progress.total) * 100).toFixed(2)}%`);
            },
            (error) => {
                console.error('Error loading GLB model:', error);
            }
        );

        // עדכון המצלמה והקנבס בזמן שינוי גודל הדפדפן
        const onResize = () => {
            sizes.width = 200;
            sizes.height = 200;
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();
            renderer.setSize(sizes.width, sizes.height);
        };
        window.addEventListener('resize', onResize);

        // לולאת האנימציה
        const clock = new THREE.Clock();
        const animate = () => {
            // סיבוב המודל סביב עצמו
            if (model) {
                model.rotation.y += 0.01; // סיבוב איטי של המודל סביב ציר ה-Y
            }

            controls.update();
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };

        animate();

        // ניקוי בעת הסרה של הקומפוננטה
        return () => {
            window.removeEventListener('resize', onResize);
            controls.dispose();
        };
    }, []);

    return <canvas className='canvas' ref={mountRef}></canvas>;
};

export default GLBModel;
