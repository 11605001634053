import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js';

const ThreeScene = () => {
    const mountRef = useRef(null);

    useEffect(() => {
        // בסיס הסצנה
        const scene = new THREE.Scene();
    
        // גודל הקנבס
        const sizes = {
            width: 300,
            height: 300
        };
    
        // Renderer
        const renderer = new THREE.WebGLRenderer({
            canvas: mountRef.current,
            antialias: true,
        });
        renderer.setSize(sizes.width, sizes.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        renderer.setClearColor(0x076c83);
    
        // Camera
        const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
        camera.position.set(1, 1, 10);
        scene.add(camera);
    
        // Controls
        const controls = new TrackballControls(camera, renderer.domElement);
        // controls.enableDamping = false; // אפשרת ריכוך לתנועה
        // controls.dampingFactor = 0.1;  // מגדיר את רמת הריכוך
        controls.rotateSpeed = 1.0;
        controls.zoomSpeed = 1.2;
        controls.panSpeed = 0.8;
        controls.noZoom = false;
        controls.noPan = false;
        
        // Font
        const fontLoader = new FontLoader();
        const textureLoader = new THREE.TextureLoader();
        const matcapTexture = textureLoader.load('textures/matcaps/4.png');
        const boxTexture = textureLoader.load('textures/matcaps/GOLDY.webp');
        const diamond = textureLoader.load('textures/matcaps/5.jpeg');

        fontLoader.load('/fonts3D/helvetiker_regular.typeface.json', (font) => {
            const materialBox = new THREE.MeshMatcapMaterial({ matcap: boxTexture });
            const textGeometry = new TextGeometry('NeeMedia ', {
                font: font,
                size: 0.5,
                height: 0.2,
                curveSegments: 12,
                bevelEnabled: true,
                bevelThickness: 0.03,
                bevelSize: 0.02,
                bevelOffset: 0,
                bevelSegments: 5
            });
            textGeometry.center();
    
            const text = new THREE.Mesh(textGeometry, materialBox);
            scene.add(text);
        });
    
        // אובייקטים נוספים כמו דונאט ותיבת היהלום
        const donutGeometry = new THREE.TorusGeometry(0.3, 0.2, 32, 64);
        const diamondGeometry = new THREE.TorusKnotGeometry(0.2, 0.1, 16, 12);
        for (let i = 0; i < 30; i++) {
            const material = new THREE.MeshMatcapMaterial({ matcap: matcapTexture });
            const diamondMeterial = new THREE.MeshMatcapMaterial({ matcap: diamond });
            const donut = new THREE.Mesh(donutGeometry, material);
            const diamonds = new THREE.Mesh(diamondGeometry, diamondMeterial);
            donut.position.set((Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10);
            donut.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, 0);
            diamonds.position.set((Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10, (Math.random() - 0.5) * 10);
            diamonds.rotation.set(Math.random() * Math.PI, Math.random() * Math.PI, 0);
            scene.add(donut);
            scene.add(diamonds);
        }
    
        // Resize handler
        const onResize = () => {
            sizes.width = 300;
            sizes.height = 300; // שומר את גובה הקנבס על 300 פיקסלים
            camera.aspect = sizes.width / sizes.height;
            camera.updateProjectionMatrix();
            renderer.setSize(sizes.width, sizes.height);
        };
        window.addEventListener('resize', onResize);
    
        // Animation loop
        const clock = new THREE.Clock();
        const animate = () => {
            const elapsedTime = clock.getElapsedTime();
        
            // Update controls for smooth interaction
            controls.update();
        
            // Hover animation for shapes
            scene.children.forEach((child) => {
                if (child instanceof THREE.Mesh && child.geometry.type !== 'TextGeometry') {
                    child.position.y += Math.sin(elapsedTime + child.id) * 0.005; // slight hover effect
                    child.rotation.x += 0.005;
                    child.rotation.y += 0.005;
                }
            });
        
            // Render scene
            renderer.render(scene, camera);
        
            // Call animate function for the next frame
            requestAnimationFrame(animate);
        };
        
        animate();
        
        return () => {
            window.removeEventListener('resize', onResize);
            controls.dispose(); // נקה את ה-Controls לאחר השימוש
        };
    }, []);
    

    return <canvas  ref={mountRef}></canvas>;
};

export default ThreeScene;
