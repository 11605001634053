import '../Main/main.css'
import { FaStar } from "react-icons/fa";
import { FaPersonChalkboard } from "react-icons/fa6";
import { PiStrategy } from "react-icons/pi";
import { LuSearchCode } from "react-icons/lu";
import ThreeScene from './ThreeScene';
import GlbModel from './GlbModel';
import AOS from 'aos';
import 'aos/dist/aos.css';
import React, { useEffect, useState } from 'react';
import Popup from '../popup/popup'
const Main =()=>{
    useEffect(() => {
        AOS.init({ 
          duration: 1000, // Animation duration
          once: true, // Whether animation should only happen once
          easing: 'ease' // Easing function for animation
        });
      }, []);

    const [isOpenPopup, setIsOpenPopup] = useState(false); 
    const [popupInfo, setPopupInfo] = useState(
    {icon:"",
        title:'',
        text:'',
}); 
const svgImage =require('../images/web.gif')
const gifImg =require('../images/social-media-final.gif')
const bot = require("../images/bot.png")
const infoForPopups =[{
    
icon:<FaStar/>,
    title:'מקצועיות וניסיון',
    text:" צוות מומחים מנוסה המשלב יצירתיות וחדשנות עם הבנה עמוקה של שוק המדיה הדיגיטלית. ",
},{
    icon:<FaPersonChalkboard/>,
        title:'שירות אישי',
        text:'אנחנו כאן בשבילכם בכל שלב, עם ליווי צמוד ותמיכה מקצועית.',
},{
    icon:<PiStrategy/>,
            title:'אסטרטגיה מותאמת אישית',
        text:'אנו בונים לכל לקוח תוכנית שיווק דיגיטלית מקיפה המותאמת לצרכים הייחודיים שלו. אנו מבינים שלכל עסק יש אתגרים ומטרות שונים ולכן אנו מציעים פתרונות מותאמים אישית להבטחת הצלחה מרבית.',
},{

    icon:<LuSearchCode/>,
        title:'שקיפות מלאה',
        text:'אנו מתחייבים לשקיפות מלאה בכל התהליכים, כך שתמיד תהיו מעודכנים לגבי התקדמות הקמפיינים והתוצאות שלהם.',
}]


const openPopUp=({icon,title,text})=>{
    document.getElementById("overlay").style.display = "block";
    setIsOpenPopup(true)
    setPopupInfo({icon:icon,
    title:title,
    text:text,
})

}
const closePopup=()=>{
    setIsOpenPopup(false)
    document.getElementById("overlay").style.display = "none";
}
const scrollToFooter = () => {
    const footer = document.querySelector('.main__form');
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

    return(
        
        <div className='main'>
<div id="overlay" onClick={closePopup}></div>

<div className='main__right-part'>    
<h2 style={{ color: "gold" }}> קח את העסק שלך צעד קדימה עם</h2>
<ThreeScene ></ThreeScene >  





{isOpenPopup? <Popup close={closePopup } info={popupInfo}></Popup>:""}
<div className='main__content'>

<span className='P__title'  > ב-Neemedia, אנו מציעים את כל הכלים והשירותים שהעסק שלכם צריך כדי לבלוט ולהצליח:</span>
    <div className='main__tittle'  >
     
<h3 style={{ margin: '0', color:"white" } } >  בניית אתרים ודפי נחיתה</h3> אתרים מרהיבים מותאמים לכל המכשירים  ודפי נחיתה ממירים שיתאימו בדיוק לצרכים שלכם ויובילו ליותר לקוחות
<img style={{ width: '100%', height: '20%' }} src={svgImage}></img>


<h2 style={{ color:"white" ,margin: '0'} }> בוטים לוואטסאפ ולאתרי וורדפרס</h2>
<div className="ai-description">
            <h2 style={{ color: "white" }}>ייעול והאצת תהליכים עסקיים באמצעות AI</h2>
            <p style={{ color: "white" }}>
                המערכת שלנו מאפשרת לכם לחסוך זמן ולהגביר את היעילות העסקית על ידי שילוב בוטים חכמים שמסוגלים לבצע פעולות באופן אוטומטי.
            </p>
            <GlbModel ></GlbModel >  

            <ul style={{ color: "white", padding:0 ,listStyleType: 'none' }}>
                <li className="bot_contant_list" ><strong style={{ color: "gold" }}>העלאת תוכן אוטומטית:</strong> בוטים מתקדמים המאפשרים להעלות תוכן ישירות לווקומרס, רשתות חברתיות וערוצים נוספים בצורה מהירה וללא טעויות.</li>
                <li><strong  style={{ color: "gold" }}>תזמון ופרסום פוסטים:</strong> ניהול לוח זמנים לפרסום פוסטים בכל הפלטפורמות הרלוונטיות, כולל יצירת תוכן שיווקי מותאם.</li>
                <li><strong style={{ color: "gold" }}>אינטגרציה עם פלטפורמות מסחר:</strong> ניהול קטלוג מוצרים, עדכוני מלאי ומחירים ישירות מהבוט, מה שמבטיח שהאתר שלכם תמיד מעודכן.</li>
                <li><strong style={{ color: "gold" }}>ניתוח ומעקב מתקדם:</strong> המערכת מציגה לכם דוחות על התוצאות ומספקת המלצות לשיפור ביצועים על סמך נתונים שנאספים בזמן אמת.</li>
            </ul>
        </div>
</div>
<br></br>
    <div className='main__title'  data-aos="flip-up" data-aos-duration="2000" >
<button className='scroll-button' onClick={scrollToFooter} >למידע נוסף</button>    
     




<h2 style={{ margin: '8', color:"gold" } } > קידום ממומן ברשתות חברתיות</h2>  נגיע לקהלים המדויקים עם קמפיינים חכמים וממוקדים בפייסבוק, אינסטגרם, לינקדאין ועוד.


<br></br>
<b style={{ margin:'5px', color:"gold" } } >קידום אתרים (SEO):</b> דאגו שהאתר שלכם יהיה בראש תוצאות החיפוש בגוגל עם אסטרטגיית SEO מותאמת אישית.
אסטרטגיות שיווק דיגיטלי מותאמות אישית נבנה עבורכם תוכנית שיווק מקיפה שתוביל אתכם להצלחה
</div>
<img style={{ width: '100%', height: '20%' }} src={gifImg}></img>

    <h2 className='logo' style={{ color: "gold" }}> NeeMedia</h2>

    <div className='main__icons'>
    
     <FaStar  className='main__icons__items icon icon1' data-aos="fade-right" data-aos-duration="1000" onClick={()=>openPopUp(infoForPopups[0])}></FaStar>
        <FaPersonChalkboard className='main__icons__items icon icon2  ' data-aos="fade-left" data-aos-duration="1000" onClick={()=>openPopUp(infoForPopups[1])} ></FaPersonChalkboard>
        <PiStrategy className='main__icons__items icon icon3  ' data-aos="fade-right" data-aos-duration="1000" onClick={()=>openPopUp(infoForPopups[2])}  ></PiStrategy>
        <LuSearchCode className='main__icons__items icon icon4  ' data-aos="fade-left" data-aos-duration="1000"   onClick={()=>openPopUp(infoForPopups[3])} ></LuSearchCode>
        <span className='main__icons__items text1'>{infoForPopups[0].title}</span>
        <span className='main__icons__items text2' >{infoForPopups[1].title}</span>
        <span className='main__icons__items text3'>{infoForPopups[2].title} </span>
        <span className='main__icons__items text4'>{infoForPopups[3].title}</span>
     
       
        </div>
        </div>
      
</div>

<div className='main__left-part'>

    <h5  className='main__form__tittle form__heder  '  data-aos="flip-down" data-aos-duration="2000" >השאירו פרטים ונחזור אליכם בהקדם</h5>
    <img style={{ width: '100%', height: '25vh' }} src={bot}></img>

<form className='main__form' action="https://formsubmit.co/supp.innomedia@gmail.com" method="POST">
<label   className='main__form__label' for="fname">שם מלא:</label>
<input className='main__form__input' type="text" name="fname" required>

</input>

{/* <label   className='main__form__label' for="mail">אימייל:</label>
<input className='main__form__input' type="email" name="mail" required></input> */}
<label   className='main__form__label' for="phone">טלפון:</label>
<input className='main__form__input' type="tel" name="phone" required></input>
<input type="hidden" name="_captcha" value="false"></input>
<input type="hidden" name="_subject" value="ליד חדש במערכת"></input>
<input type="hidden" name="_next" value="https://pinkflower.store/"></input>
<button type='submit' className='main__form__btn' >שלח</button>
</form>

</div>

</div>
    )

}
export default Main;